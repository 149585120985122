import React, { useState } from "react";
import styles from "./Tooltip.module.css";
import {
  useFloating,
  autoUpdate,
  shift,
  useHover,
  useInteractions,
  useDismiss,
  Placement,
} from "@floating-ui/react";
import clsx from "clsx";

type TooltipProps = {
  text?: React.ReactNode;
  children: React.ReactNode;
  elementWrapperClassName?: string;
  elementWrapperStyle?: React.CSSProperties;
  tooltipClassName?: string;
  defaultTooltipIsShown?: boolean;
  placement?: Placement;
};

// There is an issue when the tooltip is shown and the element moves on screen
// such as in the video buttons when the fullscreen button is toggled.
// For now we may use a key prop to reset the tooltip component,
// but we may try to add a mutation or resize observer to identify when the element moves.
const Tooltip = ({
  text,
  children,
  elementWrapperClassName,
  elementWrapperStyle,
  tooltipClassName,
  defaultTooltipIsShown = false,
  placement = "top",
}: TooltipProps) => {
  const [tooltipIsShown, setTooltipIsShown] = useState(defaultTooltipIsShown);
  const { refs, floatingStyles, context } = useFloating({
    open: tooltipIsShown,
    onOpenChange: setTooltipIsShown,
    placement,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    middleware: [shift()],
  });

  const hover = useHover(context);

  // Ensure that the tooltip is dismissed if another element is clicked (helpful for touchscreens where "hover" is not
  // detected reliably, and the reference element might become hidden, such as with the video buttons).
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
  ]);

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.childrenContainer, elementWrapperClassName)}
        ref={refs.setReference}
        {...getReferenceProps()}
        style={elementWrapperStyle}
      >
        {children}
      </div>
      {tooltipIsShown && text && (
        <div
          className={clsx(styles.tooltipContainer, tooltipClassName)}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
