import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsViewportLocked,
  setIsViewportLocked,
} from "redux/settingsRedux";
import { logUnexpectedError } from "utils/errorUtils";
import { readLargeEvent, sendLargeEventToPeers } from "utils/webrtcUtils";
import { zoomLevelType } from "./whiteboard";
import { selectUserRole, UserRole } from "redux/userRedux";
import { ViewportLockSetViewportLockedEventData } from "pages/Space/eventMessagesTypes";
import { useTrackEvent } from "utils/metricsUtils";

// Time that we will block sending scroll messages to peers after receiving a scroll message.
const PEER_SCROLL_LOCK_TIME = 200;

// Time without a scroll event to consider one scrolling session done.
const SCROLL_DONE_TIMEOUT = 200;

/**
 * Waits for scrolling to be done on the specified element.
 *
 * This function returns a promise that resolves when the element has stopped scrolling
 * for a duration defined by `SCROLL_DONE_TIMEOUT`. It sets up an event listener for the
 * "scroll" event and uses a timeout to determine when scrolling has ceased.
 *
 * In a `scrollTo()` call, multiple scroll events are triggered in rapid succession.
 *
 * @param {Element} element - The DOM element to monitor for scrolling.
 * @return {Promise<void>} A promise that resolves when scrolling is done.
 */
const waitForScrollingDone = (element: Element) => {
  return new Promise<void>((resolve) => {
    let timeoutID: NodeJS.Timeout;
    const timeoutHandler = () => {
      element.removeEventListener("scroll", onScroll);
      resolve();
    };
    const onScroll = () => {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(timeoutHandler, SCROLL_DONE_TIMEOUT);
    };
    timeoutID = setTimeout(timeoutHandler, SCROLL_DONE_TIMEOUT);
    element.addEventListener("scroll", onScroll);
  });
};

/**
 * Calculates the scroll position of an element as a percentage.
 *
 * @param {Element} element - The DOM element for which to calculate the scroll position.
 * @return {number} The scroll position as a percentage, ranging from 0 to 1.
 *
 * @remarks
 * This function ensures that the returned percentage is always between 0 and 1.
 * If the scroll height minus the client height is zero, it logs an unexpected error
 * and returns 0 to prevent division by zero.
 */
const getScrollPositionInPercent = (element: Element) => {
  const { scrollTop, clientHeight, scrollHeight } = element;

  // prevent division by zero
  // This scenario is expected when toggling the viewport lock when there is no scroll available
  if (scrollHeight - clientHeight === 0) {
    return 0;
  }
  const scrollPercent = scrollTop / (scrollHeight - clientHeight);

  // Ensure that the scroll percentage is between 0 and 1.
  if (scrollPercent < 0) {
    return 0;
  }
  if (scrollPercent > 1) {
    return 1;
  }
  return scrollPercent;
};

/**
 * Calculates the top scroll position in pixels of an element's based on a given percentage.
 *
 * @param {Element} element - The DOM element for which the scroll position is calculated.
 * @param {number} positionPercent - The percentage of the scroll position (0 to 1).
 * @return {number} The calculated scroll position in pixels.
 *
 * @remarks
 * This function rounds down the calculated scroll position to the nearest integer.
 */
const getScrollPositionFromPercent = (
  element: Element,
  positionPercent: number
) => {
  const { clientHeight, scrollHeight } = element;
  return Math.floor((scrollHeight - clientHeight) * positionPercent);
};

export const useLockViewport = ({
  canvasSectionRef,
  peersRef,
  zoomLevel,
  setZoomLevel,
  userCanControl,
  isZoomEnabled,
}: {
  canvasSectionRef: React.RefObject<HTMLDivElement>;
  peersRef: React.MutableRefObject<Peers>;
  zoomLevel: zoomLevelType;
  setZoomLevel: (newValue: zoomLevelType) => void;
  userCanControl: boolean;
  isZoomEnabled: boolean;
}) => {
  const isViewportLocked = useSelector(selectIsViewportLocked);
  const lastKnownScrollPositionRef = React.useRef<number>(0);
  const scrollTickingRef = React.useRef<boolean>(false);
  const lastScrollReceivedTimestampRef = React.useRef<number>(0);
  const lastKnownZoomLevelRef = React.useRef<zoomLevelType>(zoomLevel);
  const isScrollingRef = React.useRef<boolean>(false);
  const userRole = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();

  const isZoomEnabledRef = React.useRef<boolean>(isZoomEnabled);

  useEffect(() => {
    isZoomEnabledRef.current = isZoomEnabled;
  }, [isZoomEnabled]);

  // Event handler to listen to user scroll events and sync them with peers.
  // We ignore events triggered programatically, such as when we receive a scroll event from a peer.
  const handleSyncViewportScroll = useCallback(
    (event: Event) => {
      if (!isViewportLocked || !userCanControl) {
        return;
      }

      const isProcessingPeerScroll = isScrollingRef.current;
      const isScrollingConflicting =
        Date.now() - lastScrollReceivedTimestampRef.current <
        PEER_SCROLL_LOCK_TIME;

      if (isProcessingPeerScroll || isScrollingConflicting) {
        // If ignoring event due to peer scroll, update the timestamp
        // to prevent sending the next scroll events back to the peer.
        lastScrollReceivedTimestampRef.current = Date.now();
        return;
      }

      const scrollPercentage = getScrollPositionInPercent(
        event.target as Element
      );
      lastKnownScrollPositionRef.current = scrollPercentage;

      // scrollTicking is true while we wait for the requestAnimationFrame
      // This throttles the scroll event to only trigger once per browser frame,
      // since one user scroll often times queues multiple scroll events, and we
      // only want to send the last one to peers.
      if (!scrollTickingRef.current) {
        window.requestAnimationFrame(() => {
          scrollTickingRef.current = false;
          sendLargeEventToPeers(peersRef.current, "viewportLock.scroll", {
            position: lastKnownScrollPositionRef.current,
          });
        });
        scrollTickingRef.current = true;
      }
    },
    [isViewportLocked, userCanControl]
  );

  // Setting the scroll event listener manually to be able to use passive: true
  // to improve the responsiveness.
  useEffect(() => {
    canvasSectionRef.current?.addEventListener(
      "scroll",
      handleSyncViewportScroll,
      { passive: true }
    );
    return () => {
      canvasSectionRef.current?.removeEventListener(
        "scroll",
        handleSyncViewportScroll
      );
    };
  }, [handleSyncViewportScroll]);

  // Sending zoom level changes to peers whenever zoom level changes.
  useEffect(() => {
    // When the peer changes the zoom level, we set lastKnownZoomLevelRef, and
    // expect that the zoom level will change to that value.
    const hasUserZoomed = lastKnownZoomLevelRef.current !== zoomLevel;
    if (!hasUserZoomed) return;

    lastKnownZoomLevelRef.current = zoomLevel;

    if (!isViewportLocked || !userCanControl) return;

    sendLargeEventToPeers(peersRef.current, "viewportLock.setZoomLevel", {
      zoomLevel,
    });
  }, [zoomLevel]);

  // Event handler for the lock viewport button.
  const onToggleViewportLock = useCallback(() => {
    // Clients don't see the lock button, but checking here again to be safe.
    if (userRole !== UserRole.THERAPIST) return;

    const newLockState = !isViewportLocked;
    dispatch(setIsViewportLocked(newLockState));

    const scrollPercentage = getScrollPositionInPercent(
      canvasSectionRef.current as Element
    );
    lastKnownScrollPositionRef.current = scrollPercentage;

    const payload: ViewportLockSetViewportLockedEventData = newLockState
      ? {
          locked: true,
          scrollPosition: scrollPercentage,
          zoomLevel,
        }
      : { locked: false };

    sendLargeEventToPeers(
      peersRef.current,
      "viewportLock.setViewportLock",
      payload
    );
    lastScrollReceivedTimestampRef.current = Date.now();
    trackEvent("File activity scroll/zoom lock changed", {
      "Lock State": newLockState ? "enabled" : "disabled",
    });
  }, [isViewportLocked, zoomLevel]);

  /**
   * Sets the scroll position of the container element based on the given percentage.
   *
   * @param {number} positionPercent - The desired scroll position as a percentage of the total scrollable height.
   *
   * This function sets lastScrollReceivedTimestampRef to disable
   * this peer from sending the scroll event to other peers temporarily.
   *
   * This function also sets isScrollingRef to disable sending scroll events
   * until this scrolling completes (if the scrolling takes longer than PEER_SCROLL_LOCK_TIME)
   */
  const setScrollPosition = (positionPercent: number) => {
    const containerElement = canvasSectionRef.current;

    if (!containerElement) {
      logUnexpectedError(
        "containerElement is not defined when handling scroll event"
      );
      return;
    }

    lastScrollReceivedTimestampRef.current = Date.now();

    const position = getScrollPositionFromPercent(
      containerElement,
      positionPercent
    );

    isScrollingRef.current = true;
    containerElement.scrollTo({ top: position, behavior: "smooth" });
    waitForScrollingDone(containerElement).finally(() => {
      isScrollingRef.current = false;
    });
  };

  // All viewport lock messages handler
  const onReceiveMessageCallback = useCallback(async (event: MessageEvent) => {
    const data = await readLargeEvent(event);
    if (!data) {
      return;
    }
    const eventType = data.event;

    switch (eventType) {
      case "viewportLock.scroll":
        setScrollPosition(data.data.position);
        break;
      case "viewportLock.setZoomLevel":
        if (isZoomEnabledRef.current) {
          lastKnownZoomLevelRef.current = data.data.zoomLevel;
          setZoomLevel(data.data.zoomLevel);
        }
        break;
      case "viewportLock.setViewportLock":
        dispatch(setIsViewportLocked(data.data.locked));
        if (data.data.locked) {
          if (isZoomEnabledRef.current) {
            lastKnownZoomLevelRef.current = data.data.zoomLevel;
            setZoomLevel(data.data.zoomLevel);
          }
          // Wait for react to re-render with the zoom level change
          // then apply the scroll position change.
          if (canvasSectionRef.current) {
            // setScrollPosition() will set isScrollingRef to false after scrolling is done
            isScrollingRef.current = true;
            await waitForScrollingDone(canvasSectionRef.current);
            setScrollPosition(data.data.scrollPosition);
          }
        }
        break;
    }
  }, []);

  return {
    onReceiveMessageCallback,
    onToggleViewportLock,
  };
};
